*{
  padding: 0;
  margin: 0;
}

body{
  height: 100%;
  // width: 100%;
  min-width: 800px;
  overflow-x: hidden;
  font-family: "Microsoft YaHei";
  -webkit-font-smoothing: antialiased;
  color: #333;
  line-height: 1.75;
  font-size: 15px;
  background-color: #faf2ee;
}

html, body{
  scroll-behavior: smooth;
}

:root{
  --skin-color1:#5bb3df;
  --skin-color2:#9c9c9c;
  --skin-color-hover:#71cbf8;
  --skin-color-back1-1:rgb(91, 166, 201);
  --skin-color-back1-2:#66ccffa9;
  --skin-color-footer:#346d8a;
}

a{
  color: inherit;
  text-decoration: none;
}

a:link:hover{
  text-decoration: underline;
  opacity: 0.895;
}

.container{
  width: 1200px;
  margin: 0 auto;
}

.ch{
  text-align: center;
  padding: 60px 0;
}

.block{
  margin-bottom: 40px;
}

.chtitle{
  margin-bottom: 20px;
  font-size: 30px;
  display: inline-block;
  background-image: linear-gradient(to left, var(--skin-color-back1-1), var(--skin-color-back1-2));
  background-image: -webkit-linear-gradient(left, var(--skin-color-back1-1), var(--skin-color-back1-2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

a.button{
  text-decoration: none;
  display: inline-block;
  padding: 6px 20px;
  background: linear-gradient(to top, var(--skin-color-back1-1), var(--skin-color-back1-2));
  color: #fff;
  border-radius: 6px;
  margin-right: 10px;
  text-shadow: none;
  border-radius: 99px;
}

a.button:hover{
  text-decoration: none;
}

::selection {
  background-color: rgba(0,0,0,.1);
}

.article{
  color: var(--skin-color2);
}

.hover-in{
	transition: .3s ease-out;
}
.hover-out{
	transition: .3s ease-in;
}

.header{
  position: fixed;
  z-index: 90;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0,0,0,.02);
  transition: .3s all;

  &.transparent{
    background-color: transparent;
    color: #fff;
    .right a{
      color: #fff;
    }
    .site-logo.black{
      display: none !important;
    }
    .site-logo.white{
      display: block !important;
    }
  }
  
  .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
  }

  .right a{
    display: inline-block;
    text-decoration: none;
    color: initial;
    line-height: 50px;
    padding: 0 20px;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }

  .left{
    color: #bf2d26;
    font-weight: bold;
    .site-logo{
      height: 50px;
    }
    .site-logo.black{
      display: block;
    }
    .site-logo.white{
      display: none;
    }
  }

  .right a:hover{
    border-bottom: 3px solid var(--skin-color-hover);
    color: var(--skin-color-hover);
  }

  .right a.active{
    border-bottom: 3px solid var(--skin-color1);
    color: var(--skin-color1);
  }

  .mobile-right{
    position: absolute;
    right: 5%;
    top: 0;
    height: 100%;
    width: 60px;
    display: none;
  }

  .mobile-header-button {
    padding: 0 10px;
    height: 40px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
      width: 26px;
      height: 26px;
    }
  }
}

.app{
  position: relative;
  z-index: 3;
  background-color: #faf2ee;
}

.splash-screen{
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #212121;
  background-size: cover;
  background-position: center center;
  .background{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    &::after{
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 1;
      background-color: rgba(0,0,0,.5);
    }
  }
  .content{
    position: relative;
    z-index: 2;
    color: #fff;
    font-size: 40px;
    text-align: center;
    .intro{
      margin-top: 50px;
     a{
       font-size: 18px;
       font-weight: normal;
       background-color: rgba(0,0,0,.3);
       padding: 16px 50px;
       border-radius: 99px;
     }
     a:hover{
       text-decoration: none;
     }
    }
  }
}

.page-index{
  margin-top: 130vh;
  padding-top: 60px;
  position: relative;
  &::before{
    position: absolute;
    top: -30vh;
    left: 0;
    content: '';
    display: block;
    height: 30vh;
    width: 100%;
    background-image: linear-gradient(to top, rgba(250,242,238, 1), rgba(250,242,238, 0));
  }
}

.splash-swiper{
  height: 100%;
  width: 100%;
  .swiper-wrapper{
    .swiper-slide{
      height: 100%;
      width: 100%;
      background-position: center center;
      background-size: cover;
      position: relative;
      .cover{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,.3);
      }
    }
  }
}

.page-main{
  content: '';
  margin-top: 100px;
  position: relative;
  z-index: 5;
  background-color: #faf2ee;
}


.page-index .swiper-box{
  margin-top: 80px;
  margin-bottom: 40px;
  height: 500px;
  // width: 100%;
  background: #222;
  color: rgba(255,255,255,.1);
  display: flex;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(0,0,0,.08);
  justify-content: center;
  align-items: center;
  font-size: 50px;
  .swiper-container{
    height: 100%;
    width: 100%;
    .swiper-wrapper{
      .swiper-slide{
        height: 100%;
        width: 100%;
        background-position: center center;
        background-size: cover;
      }
    }
  }
}

.page-index .about h3{
  margin-bottom: 20px;
  font-size: 30px;
  display: inline-block;
  background-image: linear-gradient(to left, rgb(216,181,93), rgb(145,124,76));
  background-image: -webkit-linear-gradient(left, rgb(216,181,93),  rgb(145,124,76));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-index .about{
  color: var(--skin-color2);
}

.page-index .about .item{
  padding: 40px 0;
}

.page-index .about .reversal{
  text-align: right;
}

.page-index .enter-block{
  display: flex;
  text-align: center;
  color: #fff;
  .item{
    position: relative;
    overflow: hidden;
    background: url('./imgs/block/projects.jpg') no-repeat;
    background-position: center 35%;
    background-size: cover;
    overflow: hidden;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0,0,0,.4);
      transition: .3s all;
    }
    a{
      display: block;
      height: 100%;
      width: 100%;
    }
    a:hover{
      text-decoration: none;
    }
    h3{
      position: absolute;
      bottom: -30px;
      right: -10px;
      text-align: right;
      z-index: 10;
      font-size: 70px;
      opacity: .7;
      transition: .3s all;
    }

    &:hover {
      h3{
        opacity: 1;
        color: rgba(0,0,0,.8);
        bottom: 0px;
        right: 5%;
      }
      &:after{
        background-color: rgba(0,0,0,.0);
      }
    }
  }
  .item-project-box{
    width: 46%;
    height: 400px;
    margin-right: 4%;
  }
  .item-project{
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: #212121;
    background: url('./imgs/block/projects.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .right{
    width: 50%;
    .item{
      height: 120px;
      background-color: #212121;
      border-radius: 10px;
      margin-bottom: 20px;
    }
  }
}

.page-project{
  .fixed-title{
    top: 100px;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 999;
    background-color: #ffffffc9;
    text-align: center;
    font-size: 22px;
    display: none;
    backdrop-filter: blur(10px);
    h2{
      font-weight: normal;
      margin: 0;
    }
    span{
      font-size: 13px;
      font-weight: normal;
      display: inline-block;
      margin-left: 20px;
    }
  }
  @media (max-width: 800px)
  {
    .fixed-title{
      top: 60px;
    }
  }
  .project-nav{
    text-align: center;
    a{
      display: inline-block;
      padding: 5px 15px;
      background: linear-gradient(to top, rgba(0,0,0,.08), rgba(0,0,0,.1));
      border-radius: 6px;
      margin: 0 10px;
      transition: .3s all;
    }
    a:hover{
      text-decoration: none;
      background: linear-gradient(to top, var(--skin-color-back1-1), var(--skin-color-back1-2));
      color: #fff;
    }
  }
  .item-title{
    margin: 40px 0;
    text-align: center;
    font-size: 22px;
    h2{
      font-weight: normal;
      margin: 0;
    }
    span{
      font-size: 13px;
      font-weight: normal;
      display: inline-block;
      margin-left: 20px;
    }
  }
}

.product-block-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .product-block-wrapper{
    width: 49%;
    height: 280px;
    margin: 10px 0;
  }
}

.products .product-block {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;

  .item-img{
    height: 100%;
    width: 100%;
  
    background-color: #efedce;
    background-image: url(https://thonly.name/image/intro-post.jpg);
    background-size: cover;
    background-position: center center;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0,0,0,.12);
    overflow: hidden;
    &:after{
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 10px;
      background-color: rgba(0,0,0,.5);
    }
  }

  .item-content{
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transform: translateZ(40px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    color: #fff;
    overflow: hidden;
    text-shadow: 0 3px 10px rgba(0,0,0,.6);
    .item-content-inner{
      text-align: left;
      display: flex;
    }
    .logo-area{
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .content{
      width: 60%;
    }
    img{
      width: 85%;
    }
    img.s-logo{
      width: 70%;
    }
    h3{
      margin-bottom: 0px;
      font-size: 22px;
      font-weight: normal;
      // background-image: linear-gradient(to left, rgb(216,181,93), rgb(145,124,76));
      // background-image: -webkit-linear-gradient(left, rgb(216,181,93),  rgb(145,124,76));
    }
    p{
      font-size: 14px;
      padding: 10px 0 20px 0;
    }
    .action{
      // margin-top: 40px;
    }
  }
}

.footer{
  background-color: var(--skin-color-footer);
  color: #fff;
  padding: 40px 0 80px 0;
  margin-top: 100px;
  .container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .left{
    .site-logo{
      height: 80px;
    }
  }
  .right{
    font-size: 12px;
    opacity: .6;
    text-align: right;
    a{
      display: block;
    }
  }
}

.joinus .list{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.joinus .item{
  box-sizing: border-box;
  display: inline-block;
  width: 31%;
  background-color: #fff;
  border-radius: 6px;
  padding: 15px 20px;
  box-shadow: 0 3px 10px rgba(0,0,0,.04);
  font-size: 14px;
}

.joinus .item h3{
  font-size: 18px;
}

.joinus .item p{
  color: #888;
}

.joinus .action{
  margin: 20px 0;
}

.page-header{
  position: relative;
  .container{
    text-align: center;
    position: relative;
  }
  height: 280px;
  .header-background{
    position: absolute;
    top: 0;
    width: 100%;
    height: 300px;
    background-color: #faf2ee;
    background-size: cover;
    background-position: center center;
    filter: blur(10px);
    transform: scale(1.2);
    
    z-index: -1;
    &::after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom,rgba(250,242,238,0) 0%,rgba(250,242,238,1) 100%);
    }
  }
  h1{
    position: relative;
    z-index: 5;
    font-size: 56px;
    line-height: 280px;
  }
  .bg-title{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    font-size: 128px;
    line-height: 240px;
    width: 100%;
    opacity: .10;
    font-weight: bolder;
    pointer-events: none;
  }
}

.page-contact{
  text-align: center;
  .block{
    margin: 80px 0 150px 0;
  }
  h3{
    font-size: 20px;
    font-weight: normal;
    background-color: var(--skin-color2);
    background-image: linear-gradient(to top, var(--skin-color-back1-1), var(--skin-color-back1-2));
    color: #fff;
    border-radius: 30px;
    height: 60px;
    line-height: 60px;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  .article{
  }
}

.timeline-area{
  .article{
    width: 70%;
    margin: 30px auto;
    margin-bottom: 60px;
  }
  .timeline-box{
    position: relative;
    width: 80%;
    margin: 0 auto;
    &::after{
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 3px;
      height: 100%;
      background-color: rgba(0,0,0,.015);
    }
  }
  .item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: right;
    box-sizing: border-box;
    margin: 30px 0;
    min-height: 100px;
    .content{
      position: relative;
      box-sizing: border-box;
      flex-shrink: 1;
      flex-grow: 0;
      display: inline-block;
      width: 50%;
      margin: 0 40px;
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 6px 12px rgba(0,0,0,.08);
      h3{
        font-weight: normal;
        color: var(--skin-color2);
      }
    }
    .image-box{
      box-sizing: border-box;
      margin: 0 40px;
      text-align: left;
      display: block;
      width: 50%;
      img{
        max-width: 75%;
        max-height: 280px;
        border-radius: 10px;
        box-shadow: 0 6px 12px rgba(0,0,0,.08);
      }
    }
  }
  .item:nth-child(odd) {
    .content{
      &::before{
        position: absolute;
        content: '';
        right: -47px;
        top: 50%;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: #fff;
        transform: translateY(-50%);
        z-index: 10;
        box-shadow: 0 6px 12px rgba(0,0,0,.08);
      }
      &::after{
        position: absolute;
        content: '';
        right: -20px;
        top: 50%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-left: 10px solid #fff;
        transform: translateY(-50%);
      }
    }
  }
  .item:nth-child(even){
    text-align: left;
    flex-direction: row-reverse;
    .content{
      &::before{
        position: absolute;
        content: '';
        left: -46px;
        top: 50%;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: #fff;
        transform: translateY(-50%);
        z-index: 10;
        box-shadow: 0 6px 12px rgba(0,0,0,.08);
      }
      &::after{
        position: absolute;
        content: '';
        left: -20px;
        top: 50%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-right: 10px solid #fff;
        transform: translateY(-50%);
      }
    }
    .image-box{
      text-align: right;
    }
  }
}

@media (max-width: 800px) {
  body{
    min-width: 0;
    font-size: 14px;
    overflow-x: hidden;
  }
  .container{
    width: 100%;
    padding: 0 5%;
    box-sizing: border-box;
  }

  .page-main{
    margin-top: 60px;
  }

  .page-header .header-background{
    transform: scale(1);
  }

  .header{
    height: 60px;
    max-height: 60px;
    overflow: hidden;
    .container{
      height: auto;
      display: block;
    }
    .left{
      height: 60px;
      .site-logo{
        height: 30px;
        padding: 15px 0;
      }
    }
    .right{
      a{
        display: block;
      }
    }

    .mobile-right{
      display: block;
    }

    &.transparent{
      svg, svg path{
        fill: #fff;
      }
    }

    &.mobile-active{
      max-height: 1200px;
      height: auto;
      padding-bottom: 20px;
      background-color: #fff;
      color: #333;
      .right a{
        color: #333;
      }
    }
  }

  .splash-screen .content{
    font-size: 22px;
  }

  .page-header h1{
    font-size: 40px;
  }

  .page-header .bg-title{
    font-size: 60px;
  }

  .timeline-area {
    .article{
      width: auto;
    }
    .timeline-box{
      width: auto;
      &::after{
        display: none;
      }
      .item{
        display: block;
        text-align: left;
        &:nth-child(even){
          text-align: left;
          flex-direction: row;
        }
        .content::before,
        .content::after{
          display: none;
        }
        .content{
          width: 100%;
          margin: 0;
        }
        .image-box{
          width: 100%;
          margin: 0;
          text-align: center;
          img{
            max-width: 100%;
            margin: 20px 0;
          }
        }
      }
    }
  }

  .page-project .project-nav a{
    margin-bottom: 10px;
  }

  .page-project .item-title h2{
    font-size: 26px;
  }

  .product-block-list .product-block-wrapper{
    width: 100%;
    height: 220px;
  }

  .products {
    .product-block {
      .item-content{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5%;
        h3{
          font-size: 18px;
        }
        p{
          font-size: 12px;
        }
      }
    }
  }

  .footer .container{
    display: block;
    .left,
    .right{
      text-align: center;
    }
    .right{
      margin-top: 20px;
      font-size: 12px;
    }
    .left .site-logo{
      width: 70%;
      height: auto;
    }
  }

  .page-index .enter-block{
    display: block;
    height: auto;
  }

  .page-index .enter-block .item h3{
    font-size: 50px;
    bottom: -25px;
    right: -5px;
  }

  .page-index .enter-block .item-project{
    height: 200px;
  }

  .page-index .enter-block .item-project,
  .page-index .enter-block .right{
    width: 100%;
  }
}

.BackToTop {
  position: fixed;
  bottom: 50px;
  right: 15px;
	position:fixed;
	opacity:0.5;
	transition:all .5s;
	cursor:pointer;
	transform:scale(.7,.7);
	transform-origin:right bottom;
}
.BackToTop:hover {
	opacity:1;
}
